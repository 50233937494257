import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBenchmark_Model_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBenchmark_Model_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  GetBenchmarkModelTypes: any[];
  GetBenchmarkModelTypes_dummy: any[];
  GetBenchmarkProjects: any[];
  GetBenchmarkProjects_dummy: any[];
  GetBenchmarkModel: any[];
  GetBenchmarkModel_dummy: any[];
  GetAllSectors: any[];
  GetAllSectors_dummy: any[];
  GetAllArchitectures: any[];
  GetAllArchitectures_dummy: any[];
  GetAllSoftwareDevelopmentTypes: any[];
  GetAllSoftwareDevelopmentTypes_dummy: any[];
  GetAllSoftwareDevelopmentMethodologies: any[];
  GetAllSoftwareDevelopmentMethodologies_dummy: any[];
  GetAllSdlcActivities: any[];
  GetAllSdlcActivities_dummy: any[];
  GetAllProgrammingLanguages: any[];
  GetAllProgrammingLanguages_dummy: any[];
  FilterBenchmarkProjects: any[];
  FilterBenchmarkProjects_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  ValidateBenchmarkModelAdd: boolean;
  ValidateBenchmarkModelAdd_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  UpsertBenchmarkModel: any[];
  UpsertBenchmarkModel_dummy: any[];
  isComp533899Visible: "visible" | "hidden";
  isComp620629Visible: "visible" | "hidden";
}

export class Benchmark_Model_Form_ScreenBase extends React.PureComponent<IBenchmark_Model_Form_ScreenProps, any> {
  benchmark_model_form_566494_value_kuikaSelectBoxRef: React.RefObject<any>;
  benchmark_model_form_976621_value_kuikaMultiSelectRef: React.RefObject<any>;
  benchmark_model_form_250958_value_kuikaMultiSelectRef: React.RefObject<any>;
  benchmark_model_form_420262_value_kuikaMultiSelectRef: React.RefObject<any>;
  benchmark_model_form_814180_value_kuikaMultiSelectRef: React.RefObject<any>;
  benchmark_model_form_15032_value_kuikaMultiSelectRef: React.RefObject<any>;
  benchmark_model_form_430461_value_kuikaMultiSelectRef: React.RefObject<any>;
  benchmark_model_form_999186_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "8f5b0498-401d-4798-a548-388d0c23fc2c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 533899, PropertyName: "value", Value: "Efor Kestirim Modeli Oluşturma" },
        { Id: 620629, PropertyName: "value", Value: "Efor Kestirim Modeli Güncelle" },
        { Id: 669073, PropertyName: "value", Value: "Model Adı" },
        { Id: 903593, PropertyName: "value", Value: "Model Tipi" },
        { Id: 566494, PropertyName: "placeholder", Value: "Seçim yapın" },
        { Id: 609034, PropertyName: "header", Value: "Filtreler" },
        { Id: 427784, PropertyName: "value", Value: "Sektör" },
        { Id: 976621, PropertyName: "placeholder", Value: "Seçim yapın" },
        { Id: 598880, PropertyName: "value", Value: "Mimari" },
        { Id: 250958, PropertyName: "placeholder", Value: "Seçim yapın" },
        { Id: 899792, PropertyName: "value", Value: "Yazılım Geliştirme Türü" },
        { Id: 420262, PropertyName: "placeholder", Value: "Seçim yapın" },
        { Id: 818308, PropertyName: "value", Value: "Yazılım Geliştirme Metodolojisi" },
        { Id: 814180, PropertyName: "placeholder", Value: "Seçim yapın" },
        { Id: 770964, PropertyName: "value", Value: "SDLC Aktiviteleri" },
        { Id: 15032, PropertyName: "placeholder", Value: "Seçim yapın" },
        { Id: 207870, PropertyName: "value", Value: "Programlama Dili" },
        { Id: 430461, PropertyName: "placeholder", Value: "Seçim yapın" },
        { Id: 936254, PropertyName: "value", Value: "Efor" },
        { Id: 742585, PropertyName: "value", Value: "-" },
        { Id: 351007, PropertyName: "value", Value: "Toplam EP" },
        { Id: 520586, PropertyName: "value", Value: "-" },
        { Id: 536922, PropertyName: "value", Value: "Toplam CFP" },
        { Id: 822958, PropertyName: "value", Value: "-" },
        { Id: 527308, PropertyName: "label", Value: "Ara" },
        { Id: 756804, PropertyName: "title", Value: "Proje Adı" },
        { Id: 954650, PropertyName: "value", Value: "[datafield:projectname]" },
        { Id: 30741, PropertyName: "title", Value: "Sektör" },
        { Id: 51328, PropertyName: "value", Value: "[datafield:sectorname]" },
        { Id: 102055, PropertyName: "title", Value: "Mimari" },
        { Id: 572682, PropertyName: "value", Value: "[datafield:architecturename]" },
        { Id: 594965, PropertyName: "title", Value: "Yazılım Geliştirme Türü" },
        { Id: 395093, PropertyName: "value", Value: "[datafield:softwaredevelopmenttypename]" },
        { Id: 754733, PropertyName: "title", Value: "Yazılım Geliştirme Metodolojisi" },
        { Id: 807106, PropertyName: "value", Value: "[datafield:softwaredevelopmentmethodologyname]" },
        { Id: 685385, PropertyName: "title", Value: "SDLC Aktiviteleri" },
        { Id: 309569, PropertyName: "value", Value: "[datafield:sdlcactivities]" },
        { Id: 451022, PropertyName: "title", Value: "Programlama Dilleri" },
        { Id: 78916, PropertyName: "value", Value: "[datafield:programminglanguages]" },
        { Id: 938146, PropertyName: "title", Value: "Efor (Kişi/Saat)" },
        { Id: 428016, PropertyName: "value", Value: "[datafield:effort]" },
        { Id: 248244, PropertyName: "title", Value: "Toplam CFP" },
        { Id: 459720, PropertyName: "value", Value: "[datafield:cosmictotalcfp]" },
        { Id: 786425, PropertyName: "title", Value: "Toplam EP" },
        { Id: 637596, PropertyName: "value", Value: "[datafield:eventtotalep]" },
        { Id: 889818, PropertyName: "label", Value: "Kaydet" },
        { Id: 70208, PropertyName: "label", Value: "İptal" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.benchmark_model_form_173286_value_kuikaTextInputRef = React.createRef();
    this.benchmark_model_form_566494_value_kuikaSelectBoxRef = React.createRef();
    this.benchmark_model_form_976621_value_kuikaMultiSelectRef = React.createRef();
    this.benchmark_model_form_250958_value_kuikaMultiSelectRef = React.createRef();
    this.benchmark_model_form_420262_value_kuikaMultiSelectRef = React.createRef();
    this.benchmark_model_form_814180_value_kuikaMultiSelectRef = React.createRef();
    this.benchmark_model_form_15032_value_kuikaMultiSelectRef = React.createRef();
    this.benchmark_model_form_430461_value_kuikaMultiSelectRef = React.createRef();
    this.benchmark_model_form_999186_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      GetBenchmarkModelTypes: [],
      GetBenchmarkProjects: [],
      GetBenchmarkModel: [],
      GetAllSectors: [],
      GetAllArchitectures: [],
      GetAllSoftwareDevelopmentTypes: [],
      GetAllSoftwareDevelopmentMethodologies: [],
      GetAllSdlcActivities: [],
      GetAllProgrammingLanguages: [],
      FilterBenchmarkProjects: [],
      GoBack: "",
      SetValueOf: "",
      ValidateBenchmarkModelAdd: false,
      Notify: false,
      UpsertBenchmarkModel: [],
      isComp533899Visible: "hidden",
      isComp620629Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("benchmark_model_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Benchmark_Model_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("benchmark_model_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("benchmark_model_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Benchmark_Model_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      benchmark_model_form_173286_value: this.state.GetBenchmarkModel?.at?.(0)?.name ?? undefined,
      benchmark_model_form_566494_value: this.props.screenInputs.ModelTypeId ?? this.props.screenInputs.modeltypeid,
      benchmark_model_form_881777_value: undefined,
      benchmark_model_form_486010_value: undefined,
      benchmark_model_form_980441_value: undefined,
      benchmark_model_form_383311_value: undefined,
      benchmark_model_form_647724_value: undefined,
      benchmark_model_form_617590_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  Benchmark_Model_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Model tipi seçimi gerekli.",
      formName: "benchmark_model_form_566494_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      CompanyId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      ModelId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ModelId ?? this.props.screenInputs.modelid,
        "Guid"
      ),
      SectorIds_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_976621_value", "value", "GetAllSectors", "id", "id")
        ),
        "string"
      ),
      ArchitectureIds_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_250958_value",
            "value",
            "GetAllArchitectures",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SoftwareDevelopmentTypeIds_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_420262_value",
            "value",
            "GetAllSoftwareDevelopmentTypes",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SoftwareDevelopmentMethodologyIds_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_814180_value",
            "value",
            "GetAllSoftwareDevelopmentMethodologies",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SdlcActivityIds_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_15032_value",
            "value",
            "GetAllSdlcActivities",
            "id",
            "id"
          )
        ),
        "string"
      ),
      ProgrammingLanguageIds_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_430461_value",
            "value",
            "GetAllProgrammingLanguages",
            "id",
            "id"
          )
        ),
        "string"
      ),
      EffortMin_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_881777_value", "value", "", "", "")
        ),
        "number"
      ),
      EffortMax_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_486010_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalEpMin_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_980441_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalEpMax_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_383311_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalCfpMin_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_647724_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalCfpMax_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_617590_value", "value", "", "", "")
        ),
        "number"
      ),
      CompanyId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      BenchmarkModelTypeId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Benchmark_Model_Form/Benchmark_Model_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetBenchmarkModelTypes = result?.data.getBenchmarkModelTypes;

    formVars.benchmark_model_form_566494_options = stateVars.GetBenchmarkModelTypes;
    stateVars.GetBenchmarkProjects = result?.data.getBenchmarkProjects;
    stateVars.GetBenchmarkModel = result?.data.getBenchmarkModel;
    formVars.benchmark_model_form_173286_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetBenchmarkModel?.length > 0
        ? stateVars.GetBenchmarkModel[0].name
        : this.state.GetBenchmarkModel?.length > 0
        ? this.state.GetBenchmarkModel[0].name
        : null
    );
    stateVars.GetAllSectors = result?.data.getAllSectors;

    formVars.benchmark_model_form_976621_options = stateVars.GetAllSectors;
    stateVars.GetAllArchitectures = result?.data.getAllArchitectures;

    formVars.benchmark_model_form_250958_options = stateVars.GetAllArchitectures;
    stateVars.GetAllSoftwareDevelopmentTypes = result?.data.getAllSoftwareDevelopmentTypes;

    formVars.benchmark_model_form_420262_options = stateVars.GetAllSoftwareDevelopmentTypes;
    stateVars.GetAllSoftwareDevelopmentMethodologies = result?.data.getAllSoftwareDevelopmentMethodologies;

    formVars.benchmark_model_form_814180_options = stateVars.GetAllSoftwareDevelopmentMethodologies;
    stateVars.GetAllSdlcActivities = result?.data.getAllSdlcActivities;

    formVars.benchmark_model_form_15032_options = stateVars.GetAllSdlcActivities;
    stateVars.GetAllProgrammingLanguages = result?.data.getAllProgrammingLanguages;

    formVars.benchmark_model_form_430461_options = stateVars.GetAllProgrammingLanguages;
    stateVars.FilterBenchmarkProjects = result?.data.filterBenchmarkProjects;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp533899Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.ModelId ?? this.props.screenInputs.modelid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp620629Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ModelId ?? this.props.screenInputs.modelid, null) === true
        ? "visible"
        : "hidden";
    formVars.benchmark_model_form_173286_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetBenchmarkModel?.length > 0
        ? stateVars.GetBenchmarkModel[0].name
        : this.state.GetBenchmarkModel?.length > 0
        ? this.state.GetBenchmarkModel[0].name
        : null
    );

    stateVars.dataSource_566494 = this.state.GetBenchmarkModelTypes;
    stateVars.dataSource_566494 = this.state.GetBenchmarkModelTypes;

    stateVars.dataSource_976621 = this.state.GetAllSectors;
    stateVars.dataSource_976621 = this.state.GetAllSectors;

    stateVars.dataSource_250958 = this.state.GetAllArchitectures;
    stateVars.dataSource_250958 = this.state.GetAllArchitectures;

    stateVars.dataSource_420262 = this.state.GetAllSoftwareDevelopmentTypes;
    stateVars.dataSource_420262 = this.state.GetAllSoftwareDevelopmentTypes;

    stateVars.dataSource_814180 = this.state.GetAllSoftwareDevelopmentMethodologies;
    stateVars.dataSource_814180 = this.state.GetAllSoftwareDevelopmentMethodologies;

    stateVars.dataSource_15032 = this.state.GetAllSdlcActivities;
    stateVars.dataSource_15032 = this.state.GetAllSdlcActivities;

    stateVars.dataSource_430461 = this.state.GetAllProgrammingLanguages;
    stateVars.dataSource_430461 = this.state.GetAllProgrammingLanguages;

    var value_999186 = {} as any;
    value_999186.selectedValues = ReactSystemFunctions.toString(
      this,
      stateVars.GetBenchmarkModel?.length > 0
        ? stateVars.GetBenchmarkModel[0].projectIds
        : this.state.GetBenchmarkModel?.length > 0
        ? this.state.GetBenchmarkModel[0].projectIds
        : null
    );

    formVars.benchmark_model_form_999186_value = value_999186;
    stateVars.dataSource_999186 = this.state.FilterBenchmarkProjects;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Benchmark_Model_FormComponent_501895_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_FormComponent_566494_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("benchmark_model_form_999186_value", "string"),
      "",
      ReactSystemFunctions.convertToTypeByName("selectedValues", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_FormComponent_566494_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Model_FormComponent_566494_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Model tipi seçimi gerekli.",
      formName: "benchmark_model_form_566494_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SectorIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_976621_value", "value", "GetAllSectors", "id", "id")
        ),
        "string"
      ),
      ArchitectureIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_250958_value",
            "value",
            "GetAllArchitectures",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SoftwareDevelopmentTypeIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_420262_value",
            "value",
            "GetAllSoftwareDevelopmentTypes",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SoftwareDevelopmentMethodologyIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_814180_value",
            "value",
            "GetAllSoftwareDevelopmentMethodologies",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SdlcActivityIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_15032_value",
            "value",
            "GetAllSdlcActivities",
            "id",
            "id"
          )
        ),
        "string"
      ),
      ProgrammingLanguageIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_430461_value",
            "value",
            "GetAllProgrammingLanguages",
            "id",
            "id"
          )
        ),
        "string"
      ),
      EffortMin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_881777_value", "value", "", "", "")
        ),
        "number"
      ),
      EffortMax_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_486010_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalEpMin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_980441_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalEpMax_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_383311_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalCfpMin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_647724_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalCfpMax_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_617590_value", "value", "", "", "")
        ),
        "number"
      ),
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      BenchmarkModelTypeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_Form/Benchmark_Model_FormComponent_566494_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterBenchmarkProjects = result?.data.filterBenchmarkProjects;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_FormComponent_566494_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_FormComponent_566494_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_999186 = this.state.FilterBenchmarkProjects;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_FormComponent_527308_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("benchmark_model_form_999186_value", "string"),
      "",
      ReactSystemFunctions.convertToTypeByName("selectedValues", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_FormComponent_527308_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Model_FormComponent_527308_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Model tipi seçimi gerekli.",
      formName: "benchmark_model_form_566494_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SectorIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_976621_value", "value", "GetAllSectors", "id", "id")
        ),
        "string"
      ),
      ArchitectureIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_250958_value",
            "value",
            "GetAllArchitectures",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SoftwareDevelopmentTypeIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_420262_value",
            "value",
            "GetAllSoftwareDevelopmentTypes",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SoftwareDevelopmentMethodologyIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_814180_value",
            "value",
            "GetAllSoftwareDevelopmentMethodologies",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SdlcActivityIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_15032_value",
            "value",
            "GetAllSdlcActivities",
            "id",
            "id"
          )
        ),
        "string"
      ),
      ProgrammingLanguageIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_430461_value",
            "value",
            "GetAllProgrammingLanguages",
            "id",
            "id"
          )
        ),
        "string"
      ),
      EffortMin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_881777_value", "value", "", "", "")
        ),
        "number"
      ),
      EffortMax_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_486010_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalEpMin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_980441_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalEpMax_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_383311_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalCfpMin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_647724_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalCfpMax_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_617590_value", "value", "", "", "")
        ),
        "number"
      ),
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      BenchmarkModelTypeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_Form/Benchmark_Model_FormComponent_527308_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterBenchmarkProjects = result?.data.filterBenchmarkProjects;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_FormComponent_527308_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_FormComponent_527308_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_999186 = this.state.FilterBenchmarkProjects;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Model_FormComponent_889818_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      selectedProjectIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_999186_value",
            "selectedValues",
            "FilterBenchmarkProjects",
            "",
            ""
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Model_Form/Benchmark_Model_FormComponent_889818_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidateBenchmarkModelAdd = result?.data.validateBenchmarkModelAdd;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Model_FormComponent_889818_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_FormComponent_889818_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.ValidateBenchmarkModelAdd), false)) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "3197c2b9_83e9_7840_c66d_2340e9ce6af8_notify",
          this.defaultML,
          "En az 1 adet proje seçimi gerekli."
        ),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_FormComponent_889818_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_FormComponent_889818_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Model_FormComponent_889818_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_173286_value", "value", "", "", "")
        ),
        null
      ),
      message: "Model adı gerekli.",
      formName: "benchmark_model_form_173286_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Model tipi seçimi gerekli.",
      formName: "benchmark_model_form_566494_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ModelId ?? this.props.screenInputs.modelid,
        "Guid"
      ),
      Name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_model_form_173286_value", "value", "", "", "")
        ),
        "string"
      ),
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      TypeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_566494_value",
            "value",
            "GetBenchmarkModelTypes",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      CreatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      LastUpdatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      BenchmarkProjectIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_model_form_999186_value",
            "selectedValues",
            "FilterBenchmarkProjects",
            "",
            ""
          )
        ),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.ValidateBenchmarkModelAdd), true)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Model_Form/Benchmark_Model_FormComponent_889818_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpsertBenchmarkModel = result?.data.upsertBenchmarkModel;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_FormComponent_889818_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Model_FormComponent_889818_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Model_FormComponent_889818_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.ValidateBenchmarkModelAdd), true)) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Benchmark_Model_FormComponent_70208_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
