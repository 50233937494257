import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBenchmark_Prediction_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBenchmark_Prediction_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  GetOngoingBenchmarkPredictionsAsJson: any[];
  GetOngoingBenchmarkPredictionsAsJson_dummy: any[];
  GetGCPAuthToken: string;
  GetGCPAuthToken_dummy: string;
  CheckVertexAiCustomJobsStatus: string;
  CheckVertexAiCustomJobsStatus_dummy: string;
  UpdateOngoingBenchmarkPredictions: number;
  UpdateOngoingBenchmarkPredictions_dummy: number;
  GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson: any[];
  GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson_dummy: any[];
  BulkGetBenchmarkPredictionResultsFromBucket: any;
  BulkGetBenchmarkPredictionResultsFromBucket_dummy: any;
  UpdateBenchmarkPredictionEffortFromJson: number;
  UpdateBenchmarkPredictionEffortFromJson_dummy: number;
  GetBenchmarkPredictions: any[];
  GetBenchmarkPredictions_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DeleteBenchmarkPrediction: number;
  DeleteBenchmarkPrediction_dummy: number;
  CancelVertexAiCustomJob: any;
  CancelVertexAiCustomJob_dummy: any;
  UpdateBenchmarkPredictionAfterTrainingCancel: number;
  UpdateBenchmarkPredictionAfterTrainingCancel_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  GetBenchmarkPredictionMetricsAsJson: any[];
  GetBenchmarkPredictionMetricsAsJson_dummy: any[];
  SaveJsonToBucket: any;
  SaveJsonToBucket_dummy: any;
  GetBenchmarkModelBucketPath: any[];
  GetBenchmarkModelBucketPath_dummy: any[];
  CreateVertexAiCustomJob: any;
  CreateVertexAiCustomJob_dummy: any;
  UpdateBenchmarkPredictionAfterTrainingStart: number;
  UpdateBenchmarkPredictionAfterTrainingStart_dummy: number;
  isComp712797Visible: "visible" | "hidden";
  isComp714513Enabled: "enabled" | "disabled";
  isComp377821Enabled: "enabled" | "disabled";
  isComp712797Enabled: "enabled" | "disabled";
  isComp697345Enabled: "enabled" | "disabled";
  isComp896623Enabled: "enabled" | "disabled";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Benchmark_Prediction_List_ScreenBase extends React.PureComponent<
  IBenchmark_Prediction_List_ScreenProps,
  any
> {
  benchmark_prediction_list_49558_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "5620d1d0-bb43-47c5-8ad2-2ed3138f433c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 454662, PropertyName: "value", Value: "Efor Kestirimi Yapma" },
        { Id: 88616, PropertyName: "label", Value: "Ekle" },
        { Id: 534571, PropertyName: "label", Value: "Yenile" },
        { Id: 49558, PropertyName: "nodatafoundmessage", Value: "Efor kestirimi bulunamadı." },
        { Id: 860020, PropertyName: "title", Value: "İşlem" },
        { Id: 977744, PropertyName: "title", Value: "Kestirim Adı" },
        { Id: 722370, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 400, PropertyName: "title", Value: "Model Adı" },
        { Id: 912869, PropertyName: "value", Value: "[datafield:modelname]" },
        { Id: 554152, PropertyName: "title", Value: "Model Tipi" },
        { Id: 860931, PropertyName: "value", Value: "[datafield:modeltypename]" },
        { Id: 30541, PropertyName: "title", Value: "Parametre" },
        { Id: 398333, PropertyName: "value", Value: "[datafield:parameters]" },
        { Id: 35295, PropertyName: "title", Value: "Durum" },
        { Id: 262687, PropertyName: "value", Value: "[datafield:statusname]" },
        { Id: 859751, PropertyName: "title", Value: "Başlangıç Tarihi" },
        { Id: 743572, PropertyName: "value", Value: "[datafield:starttime]" },
        { Id: 903405, PropertyName: "title", Value: "Bitiş Tarihi" },
        { Id: 466416, PropertyName: "value", Value: "[datafield:endtime]" },
        { Id: 189090, PropertyName: "title", Value: "Hesaplanan Efor" },
        { Id: 483229, PropertyName: "value", Value: "[datafield:predictedefort]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.benchmark_prediction_list_49558_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      GetOngoingBenchmarkPredictionsAsJson: [],
      GetGCPAuthToken: "",
      CheckVertexAiCustomJobsStatus: "",
      UpdateOngoingBenchmarkPredictions: 0,
      GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson: [],
      BulkGetBenchmarkPredictionResultsFromBucket: "",
      UpdateBenchmarkPredictionEffortFromJson: 0,
      GetBenchmarkPredictions: [],
      NAVIGATE: "",
      DeleteBenchmarkPrediction: 0,
      CancelVertexAiCustomJob: "",
      UpdateBenchmarkPredictionAfterTrainingCancel: 0,
      Notify: false,
      GetBenchmarkPredictionMetricsAsJson: [],
      SaveJsonToBucket: "",
      GetBenchmarkModelBucketPath: [],
      CreateVertexAiCustomJob: "",
      UpdateBenchmarkPredictionAfterTrainingStart: 0,
      isComp712797Visible: "hidden",
      isComp714513Enabled: "disabled",
      isComp377821Enabled: "disabled",
      isComp712797Enabled: "disabled",
      isComp697345Enabled: "disabled",
      isComp896623Enabled: "disabled",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("benchmark_prediction_list", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.Benchmark_Prediction_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("benchmark_prediction_list", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("benchmark_prediction_list", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Benchmark_Prediction_ListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.Benchmark_Prediction_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  Benchmark_Prediction_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      CompanyId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAuthToken_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingBenchmarkPredictionsAsJson?.length > 0
            ? stateVars.GetOngoingBenchmarkPredictionsAsJson[0].ongoingBenchmarkPredictions
            : this.state.GetOngoingBenchmarkPredictionsAsJson?.length > 0
            ? this.state.GetOngoingBenchmarkPredictionsAsJson[0].ongoingBenchmarkPredictions
            : null
        ),
        "string"
      ),
      OngoingPredictionStatusResultsJson_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      CompanyId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAccessToken_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson?.length > 0
            ? stateVars.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson[0]
                .finishedBenchmarkPredictionsWithoutPredictedEffort
            : this.state.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson?.length > 0
            ? this.state.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson[0]
                .finishedBenchmarkPredictionsWithoutPredictedEffort
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetOngoingBenchmarkPredictionsAsJson = result?.data.getOngoingBenchmarkPredictionsAsJson;
    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingBenchmarkPredictions = result?.data.updateOngoingBenchmarkPredictions;
    stateVars.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson =
      result?.data.getFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson;
    stateVars.BulkGetBenchmarkPredictionResultsFromBucket = result?.data.bulkGetBenchmarkPredictionResultsFromBucket;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      BenchmarkPredictionEffortInfoJson_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.BulkGetBenchmarkPredictionResultsFromBucket?.predictionRecords),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.BulkGetBenchmarkPredictionResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_List/Benchmark_Prediction_ListPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateBenchmarkPredictionEffortFromJson = result?.data.updateBenchmarkPredictionEffortFromJson;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListPageInit2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetBenchmarkPredictions = result?.data.getBenchmarkPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_49558 = this.state.GetBenchmarkPredictions;
    stateVars.isComp714513Enabled =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "statusName"),
        "Bitti"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "statusName"),
          "Devam Ediyor"
        )) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp377821Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp712797Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "statusName"),
        "Bitti"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp697345Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp896623Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Benchmark_Prediction_ListComponent_88616_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Benchmark_Prediction_Form",
      "BenchmarkPredictionId",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Benchmark_Prediction_List",
      "Benchmark_Prediction_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "870476",
      null,
      null,
      "true",
      "80vw",
      "70vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Prediction_ListComponent_534571_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAuthToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingBenchmarkPredictionsAsJson?.length > 0
            ? stateVars.GetOngoingBenchmarkPredictionsAsJson[0].ongoingBenchmarkPredictions
            : this.state.GetOngoingBenchmarkPredictionsAsJson?.length > 0
            ? this.state.GetOngoingBenchmarkPredictionsAsJson[0].ongoingBenchmarkPredictions
            : null
        ),
        "string"
      ),
      OngoingPredictionStatusResultsJson_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      CompanyId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAccessToken_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson?.length > 0
            ? stateVars.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson[0]
                .finishedBenchmarkPredictionsWithoutPredictedEffort
            : this.state.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson?.length > 0
            ? this.state.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson[0]
                .finishedBenchmarkPredictionsWithoutPredictedEffort
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_534571_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOngoingBenchmarkPredictionsAsJson = result?.data.getOngoingBenchmarkPredictionsAsJson;
    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingBenchmarkPredictions = result?.data.updateOngoingBenchmarkPredictions;
    stateVars.GetFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson =
      result?.data.getFinishedBenchmarkPredictionsWithoutPredictedEffortAsJson;
    stateVars.BulkGetBenchmarkPredictionResultsFromBucket = result?.data.bulkGetBenchmarkPredictionResultsFromBucket;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListComponent_534571_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_534571_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BenchmarkPredictionEffortInfoJson_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.BulkGetBenchmarkPredictionResultsFromBucket?.predictionRecords),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.BulkGetBenchmarkPredictionResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_534571_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateBenchmarkPredictionEffortFromJson = result?.data.updateBenchmarkPredictionEffortFromJson;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_534571_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_534571_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_534571_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_534571_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetBenchmarkPredictions = result?.data.getBenchmarkPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListComponent_534571_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_534571_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_49558 = this.state.GetBenchmarkPredictions;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Prediction_ListComponent_714513_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Benchmark_Prediction_Form",
      "BenchmarkPredictionId",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Benchmark_Prediction_List",
      "Benchmark_Prediction_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "854982",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Prediction_ListComponent_377821_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BenchmarkPredictionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "Guid"
      ),
      CompanyId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7fda394a_f141_278b_df36_b26ecd0e3d29_confirmation",
        this.defaultML,
        "Silmek istediğinizden emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-roboto"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_377821_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteBenchmarkPrediction = result?.data.deleteBenchmarkPrediction;
      stateVars.GetBenchmarkPredictions = result?.data.getBenchmarkPredictions;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_377821_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_377821_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_49558 = this.state.GetBenchmarkPredictions;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Prediction_ListComponent_712797_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Benchmark_Prediction_Result",
      "BenchmarkPredictionId",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Benchmark_Prediction_List",
      "Benchmark_Prediction_Result",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "473691",
      null,
      null,
      "true",
      "400px",
      "400vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Prediction_ListComponent_697345_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_697345_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListComponent_697345_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_697345_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      customJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "vertexCustomJobName"),
        "string"
      ),
      BenchmarkPredictionId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "Guid"
      ),
      CompanyId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7ff06ac2_44ca_1b8a_6b6f_888fc4554baa_confirmation",
        this.defaultML,
        "Efor kestirimini iptal etmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-roboto"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_697345_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CancelVertexAiCustomJob = result?.data.cancelVertexAiCustomJob;
      stateVars.UpdateBenchmarkPredictionAfterTrainingCancel =
        result?.data.updateBenchmarkPredictionAfterTrainingCancel;
      stateVars.GetBenchmarkPredictions = result?.data.getBenchmarkPredictions;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_697345_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_697345_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "04fb0ba5_cd05_b625_150b_9155af1e33d1_notify",
        this.defaultML,
        "Efor kestirim işlemi iptal edildi."
      ),
      ReactSystemFunctions.convertToTypeByName("success", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("5", "number"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("0", "number")
    );

    stateVars.dataSource_49558 = this.state.GetBenchmarkPredictions;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Prediction_ListComponent_896623_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BenchmarkModelId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "Guid"
      ),
      jsonString_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetBenchmarkPredictionMetricsAsJson?.length > 0
            ? stateVars.GetBenchmarkPredictionMetricsAsJson[0].benchmarkPredictionMetrics
            : this.state.GetBenchmarkPredictionMetricsAsJson?.length > 0
            ? this.state.GetBenchmarkPredictionMetricsAsJson[0].benchmarkPredictionMetrics
            : null
        ),
        "string"
      ),
      gcpAccessToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      prefix_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "string"
      ),
      folderName_2: ReactSystemFunctions.convertToTypeByName("effort_data", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_896623_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.GetBenchmarkPredictionMetricsAsJson = result?.data.getBenchmarkPredictionMetricsAsJson;
    stateVars.SaveJsonToBucket = result?.data.saveJsonToBucket;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result), "error")
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "modelId"),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_896623_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetBenchmarkModelBucketPath = result?.data.getBenchmarkModelBucketPath;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      task_0: ReactSystemFunctions.convertToTypeByName("eff_pred", "string"),
      modelBucketPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetBenchmarkModelBucketPath?.length > 0
            ? stateVars.GetBenchmarkModelBucketPath[0].benchmarkModelBucketPath
            : this.state.GetBenchmarkModelBucketPath?.length > 0
            ? this.state.GetBenchmarkModelBucketPath[0].benchmarkModelBucketPath
            : null
        ),
        "string"
      ),
      jsonBucketPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.bucketPath),
        "string"
      ),
      targetKey_0: ReactSystemFunctions.convertToTypeByName("effort", "string"),
      jobId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "string"
      ),
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserID
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserID
            : null
        ),
        "string"
      ),
      modelTypeCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "typeCode"),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_896623_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CreateVertexAiCustomJob = result?.data.createVertexAiCustomJob;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      StartTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      VertexCustomJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.customJob?.name),
        "string"
      ),
      BenchmarkPredictionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "benchmark_prediction_list_49558_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_896623_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateBenchmarkPredictionAfterTrainingStart = result?.data.updateBenchmarkPredictionAfterTrainingStart;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "error"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "4b30c2a5_4454_73be_af5d_78b72fc5d6bf_notify",
          this.defaultML,
          "Efor kestirim işlemi başladı."
        ),
        ReactSystemFunctions.convertToTypeByName("success", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Prediction_List/Benchmark_Prediction_ListComponent_896623_onClick7_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetBenchmarkPredictions = result?.data.getBenchmarkPredictions;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Prediction_ListComponent_896623_onClick8_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Prediction_ListComponent_896623_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_49558 = this.state.GetBenchmarkPredictions;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [870476, 854982, 473691] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Benchmark_Prediction_ListPageInit();
    }
  }
}
