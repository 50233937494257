import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IModel_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IModel_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  SaveRecord_1: number;
  SaveRecord_1_dummy: number;
  SaveRecord_2: number;
  SaveRecord_2_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetModelsDropdown: any[];
  GetModelsDropdown_dummy: any[];
  GetProjectsDropdown: any[];
  GetProjectsDropdown_dummy: any[];
  GetMetricsDropdown: any[];
  GetMetricsDropdown_dummy: any[];
  GetModel: any[];
  GetModel_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  GetProjectMetricsDropdown: any[];
  GetProjectMetricsDropdown_dummy: any[];
}

export class Model_Form_ScreenBase extends React.PureComponent<IModel_Form_ScreenProps, any> {
  model_form_792493_value_kuikaSelectBoxRef: React.RefObject<any>;
  model_form_989148_value_kuikaSelectBoxRef: React.RefObject<any>;
  model_form_769055_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "f9d57348-000b-4182-9dc4-3271c917a4e2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 306657, PropertyName: "label", Value: "Kaydet" },
        { Id: 636116, PropertyName: "label", Value: "İptal" },
        { Id: 520608, PropertyName: "value", Value: "Model Detay" },
        { Id: 776063, PropertyName: "value", Value: "Model Adı" },
        { Id: 69216, PropertyName: "value", Value: "Proje" },
        { Id: 470209, PropertyName: "value", Value: "Metrik" },
        { Id: 436034, PropertyName: "value", Value: "Baz Model" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.model_form_954700_value_kuikaTextInputRef = React.createRef();
    this.model_form_792493_value_kuikaSelectBoxRef = React.createRef();
    this.model_form_989148_value_kuikaSelectBoxRef = React.createRef();
    this.model_form_769055_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      SaveRecord_1: false,
      SaveRecord_2: false,
      GoBack: "",
      MyContext: [],
      GetModelsDropdown: [],
      GetProjectsDropdown: [],
      GetMetricsDropdown: [],
      GetModel: [],
      TriggerEvent: "",
      GetProjectMetricsDropdown: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("model_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Model_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("model_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("model_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Model_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      model_form_954700_value: this.state.GetModel?.at?.(0)?.modelName ?? undefined,
      model_form_792493_value: this.state.GetModel?.at?.(0)?.projectID ?? undefined,
      model_form_989148_value: this.state.GetProjectMetricsDropdown?.at?.(0)?.metric ?? undefined,
      model_form_769055_value: this.state.GetModel?.at?.(0)?.parentModelID ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  Model_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      CompanyID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      ModelID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ModelID ?? this.props.screenInputs.modelid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Model_Form/Model_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetModelsDropdown = result?.data.getModelsDropdown;

    formVars.model_form_769055_value =
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].parentModelID
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].parentModelID
        : null;
    formVars.model_form_769055_options = stateVars.GetModelsDropdown;
    stateVars.GetProjectsDropdown = result?.data.getProjectsDropdown;

    formVars.model_form_792493_value =
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].projectID
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].projectID
        : null;
    formVars.model_form_792493_options = stateVars.GetProjectsDropdown;
    stateVars.GetMetricsDropdown = result?.data.getMetricsDropdown;
    stateVars.GetModel = result?.data.getModel;
    formVars.model_form_954700_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].modelName
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].modelName
        : null
    );
    formVars.model_form_792493_value =
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].projectID
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].projectID
        : null;
    formVars.model_form_792493_options = stateVars.GetProjectsDropdown;
    formVars.model_form_769055_value =
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].parentModelID
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].parentModelID
        : null;
    formVars.model_form_769055_options = stateVars.GetModelsDropdown;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.IsEdit ?? this.props.screenInputs.isedit, true)) {
      if (await this.Model_FormComponent_792493_onChange()) return true;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_FormPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_FormPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.model_form_954700_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].modelName
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].modelName
        : null
    );

    formVars.model_form_792493_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].projectID
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].projectID
        : null
    );

    stateVars.dataSource_792493 = this.state.GetProjectsDropdown;
    stateVars.dataSource_792493 = this.state.GetProjectsDropdown;
    formVars.model_form_769055_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModel?.length > 0
        ? stateVars.GetModel[0].parentModelID
        : this.state.GetModel?.length > 0
        ? this.state.GetModel[0].parentModelID
        : null
    );

    stateVars.dataSource_769055 = this.state.GetModelsDropdown;
    stateVars.dataSource_769055 = this.state.GetModelsDropdown;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Model_FormComponent_306657_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_form_989148_value",
            "value",
            "GetProjectMetricsDropdown",
            "metric",
            ""
          )
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "model_form_989148_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "model_form_954700_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "model_form_954700_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "model_form_792493_value", "value", "GetProjectsDropdown", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "model_form_792493_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ModelID ?? this.props.screenInputs.modelid,
        "Guid"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      MetricID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "model_form_989148_value",
            "value",
            "GetProjectMetricsDropdown",
            "metric",
            "metricId"
          )
        ),
        "Guid"
      ),
      ParentModelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_form_769055_value", "value", "GetModelsDropdown", "id", "id")
        ),
        "Guid"
      ),
      ModelName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_form_954700_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_form_792493_value", "value", "GetProjectsDropdown", "id", "id")
        ),
        "Guid"
      ),
      CreateDatetime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserID
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserID
            : null
        ),
        "Guid"
      ),
      StatusID_0: ReactSystemFunctions.convertToTypeByName("b28263af-1fa5-4c08-9ef2-edc0ba526d75", "Guid")
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetModel?.length > 0
            ? stateVars.GetModel[0].modelName
            : this.state.GetModel?.length > 0
            ? this.state.GetModel[0].modelName
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Model_Form/Model_FormComponent_306657_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord_1 = result?.data.saveRecord_1;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_FormComponent_306657_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_FormComponent_306657_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_FormComponent_306657_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_form_989148_value",
            "value",
            "GetProjectMetricsDropdown",
            "metric",
            ""
          )
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "model_form_989148_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "model_form_954700_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "model_form_954700_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "model_form_792493_value", "value", "GetProjectsDropdown", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "model_form_792493_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ModelID ?? this.props.screenInputs.modelid,
        "Guid"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      MetricID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "model_form_989148_value",
            "value",
            "GetProjectMetricsDropdown",
            "metric",
            "id"
          )
        ),
        "Guid"
      ),
      ParentModelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_form_769055_value", "value", "GetModelsDropdown", "id", "id")
        ),
        "Guid"
      ),
      ModelName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_form_954700_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_form_792493_value", "value", "GetProjectsDropdown", "id", "id")
        ),
        "Guid"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserID
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserID
            : null
        ),
        "Guid"
      ),
      StatusID_0: ReactSystemFunctions.convertToTypeByName("b28263af-1fa5-4c08-9ef2-edc0ba526d75", "Guid")
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetModel?.length > 0
            ? stateVars.GetModel[0].modelName
            : this.state.GetModel?.length > 0
            ? this.state.GetModel[0].modelName
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Model_Form/Model_FormComponent_306657_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord_2 = result?.data.saveRecord_2;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_FormComponent_306657_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_FormComponent_306657_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_FormComponent_306657_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_FormComponent_636116_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_FormComponent_846558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_FormComponent_792493_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "model_form_792493_value", "value", "GetProjectsDropdown", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "model_form_792493_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjectId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_form_792493_value", "value", "GetProjectsDropdown", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Model_Form/Model_FormComponent_792493_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetProjectMetricsDropdown = result?.data.getProjectMetricsDropdown;

    formVars.model_form_989148_value =
      stateVars.GetProjectMetricsDropdown?.length > 0
        ? stateVars.GetProjectMetricsDropdown[0].metric
        : this.state.GetProjectMetricsDropdown?.length > 0
        ? this.state.GetProjectMetricsDropdown[0].metric
        : null;
    formVars.model_form_989148_options = stateVars.GetProjectMetricsDropdown;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_FormComponent_792493_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_FormComponent_792493_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.model_form_989148_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProjectMetricsDropdown?.length > 0
        ? stateVars.GetProjectMetricsDropdown[0].metric
        : this.state.GetProjectMetricsDropdown?.length > 0
        ? this.state.GetProjectMetricsDropdown[0].metric
        : null
    );

    formVars.model_form_989148_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProjectMetricsDropdown?.length > 0
        ? stateVars.GetProjectMetricsDropdown[0].metric
        : this.state.GetProjectMetricsDropdown?.length > 0
        ? this.state.GetProjectMetricsDropdown[0].metric
        : null
    );

    stateVars.dataSource_989148 = this.state.GetProjectMetricsDropdown;
    stateVars.dataSource_989148 = this.state.GetProjectMetricsDropdown;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
